///////////////////////
//Navigation///
/////////////////////


const offCanvasNavigation = (function() {

	const $trigger = $('a.main-nav-trigger');

	let isNavAnimating = false;
	let isOpen = false;

	const animateNav = (event) =>{

		let $target = $(event.target);
		let refactoredTrigger = $trigger.get(0);
		let refactoredTarget	= $target.get(0);
		event.preventDefault();
		
		if (!isNavAnimating) {	

			if ($(this).parents('.csstransitions').length > 0) isNavAnimating = true;

			if( isOpen == true ){
				isOpen = false;
				$('body').removeClass('navigation-is-open');
				return
			}

			if (isOpen == false ) {
				isOpen = true;
				$('body').addClass('navigation-is-open');
				return
			}

			$('.main-navigation-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
      	//animation is over
      	isNavAnimating = false;
      });

		}
	}

	const closeNav = (event) =>{
		$('body').removeClass('navigation-is-open');
		$('.main-navigation-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
    	//animation is over
    	isNavAnimating = false;
    });
	};

	$trigger.on('click', animateNav); 
	$('.toggle-wrap .tools').on('click',closeNav); 

})();

export { offCanvasNavigation };
