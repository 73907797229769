const datepicker = (function () {
    let emailConfirmFields = $('.emailconfirm');
    $.each(emailConfirmFields, function () {
        let field = $(this);
        $(this).on('change', function () {
            let pass = checkEmail(field);
            if (pass) {
                field.removeClass('error');
            } else {
                field.addClass('error');
            }
        });
    });
})();

function checkEmail(confirmEmailField) {
    let emailField = confirmEmailField.closest('form').find('input.email');
    if (emailField.length) {
        if (emailField.val() === confirmEmailField.val()) {
            return true;
        } else {
            return false
        }
    }
}


if ($('select.ferry-transfer').children("option:selected").val() == 'No') {
    $("input.ferry-detail").parents(".form-group").hide();
    $(".no-pax-travelling").parents(".form-group").hide();
} else {
    $("input.ferry-detail").parents(".form-group").show();
    $(".no-pax-travelling").parents(".form-group").show();
}


$('select.ferry-transfer').change(function () {
    let selected = $(this).children("option:selected").val();
    if (selected == 'Yes') {
        $("input.ferry-detail").parents(".form-group").show();
        $(".no-pax-travelling").parents(".form-group").show();
    } else {
        $("input.ferry-detail").parents(".form-group").hide();
        $(".no-pax-travelling").parents(".form-group").hide();
    }
});



if ($('select.include-bikes').children("option:selected").val() == 'No') {
    $(".no-bikes-required").parents(".form-group").hide();
} else {
    $(".no-bikes-required").parents(".form-group").show();
}

$('select.include-bikes').change(function () {
    let selected = $(this).children("option:selected").val();
    if (selected == 'Yes') {
        $(".no-bikes-required").parents(".form-group").show();
    } else {
        $(".no-bikes-required").parents(".form-group").hide();
    }
});

$(".passenger-info").hide();
let totalTravellers = $("#totaltravellers").val();

if(totalTravellers == '1'){
    $(".form-group.passenger-1").show();
    $(".form-group.passenger-1 input").attr('required',true);
}else if(totalTravellers == '2') {
    $(".form-group.passenger-1").show();
    $(".form-group.passenger-1 input").attr('required','required');
    $(".form-group.passenger-2").show();
    $(".form-group.passenger-2 input").attr('required','required');
}else if(totalTravellers == '3') {
    $(".form-group.passenger-1").show();
    $(".form-group.passenger-1 input").attr('required','required');
    $(".form-group.passenger-2").show();
    $(".form-group.passenger-2 input").attr('required','required');
    $(".form-group.passenger-3").show();
    $(".form-group.passenger-3 input").attr('required','required');
}else if(totalTravellers == '4') {
    $(".form-group.passenger-1").show();
    $(".form-group.passenger-1 input").attr('required','required');
    $(".form-group.passenger-2").show();
    $(".form-group.passenger-2 input").attr('required','required');
    $(".form-group.passenger-3").show();
    $(".form-group.passenger-3 input").attr('required','required');
    $(".form-group.passenger-4").show();
    $(".form-group.passenger-4 input").attr('required','required');
}

/*$("select.required-seat").change(function(){
    $(".passenger-info").hide();
    $(".passenger-info input").attr('required',false);

    let selected = $(this).children("option:selected").val();
    if(selected == '1'){
        $(".form-group.passenger-1").show();
        $(".form-group.passenger-1 input").attr('required',true);
    }else if(selected == '2') {
        $(".form-group.passenger-1").show();
        $(".form-group.passenger-1 input").attr('required','required');
        $(".form-group.passenger-2").show();
        $(".form-group.passenger-2 input").attr('required','required');
    }else if(selected == '3') {
        $(".form-group.passenger-1").show();
        $(".form-group.passenger-1 input").attr('required','required');
        $(".form-group.passenger-2").show();
        $(".form-group.passenger-2 input").attr('required','required');
        $(".form-group.passenger-3").show();
        $(".form-group.passenger-3 input").attr('required','required');
    }else if(selected == '4') {
        $(".form-group.passenger-1").show();
        $(".form-group.passenger-1 input").attr('required','required');
        $(".form-group.passenger-2").show();
        $(".form-group.passenger-2 input").attr('required','required');
        $(".form-group.passenger-3").show();
        $(".form-group.passenger-3 input").attr('required','required');
        $(".form-group.passenger-4").show();
        $(".form-group.passenger-4 input").attr('required','required');
    }
});*/

$('select.airport-delivery').change(function(){
    let selected = $(this).children("option:selected").val();
    if(selected == 'yes'){
        $("input.flight-number").parents(".form-group").show();
        $("input.other-delivery-address").parents(".form-group").hide();
    }else{
        $("input.other-delivery-address").parents(".form-group").show();
        $("input.flight-number").parents(".form-group").hide();
    }
});


$('select.airport-transfer').change(function(){
    let selected = $(this).children("option:selected").val();
    if(selected == 'yes'){
        $("input.depart-flight-number").parents(".form-group").show();
        $("input.other-transfer-address").parents(".form-group").hide();
    }else{
        $("input.other-transfer-address").parents(".form-group").show();
        $("input.depart-flight-number").parents(".form-group").hide();
    }
});