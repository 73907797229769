const owlSlider = (function() {


	$('.owl-carousel').owlCarousel({
		loop:true,
		autoplay:false,
		margin: 3,
		items:8,
		nav:true,
		dots: false,
		navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
		touchDrag:false,
		mouseDrag: false,
		pullDrag:false,
		URLhashListener:true,
		startPosition: 'URLHash',
		responsive:{
			0:{
				items:3,
				touchDrag:true
			},
			480:{
				items:3,
				touchDrag:true
			},
			768:{
				items:5,
				touchDrag:true
			},
			991:{
				items:5,
				loop:false
			},
			1200:{
				items:8,
				loop:false
			},
		}
	});



})();

