//
//

// Vendor imports

import jQuery from 'Node/jquery'

import './vendor/owl.carousel.min.js';
import './vendor/lettering.min.js';
import { owlSlider } from "./_modules/_owl.carousel.js";
import { scrollAnchor } from "./_modules/_scrollAnchor.js";
import { offCanvasNavigation } from "./_modules/navigation/_offCanvasNav.js";
import ScrollReveal from 'Node/scrollreveal';
import 'Node/jquery-match-height';
import { datepicker } from "./_modules/_datepicker.js";
import { quoteForm } from "./_modules/_quoteform.js";


import "./_modules/_map-selector.js"

// navigation imports

import { markActive } from "./_modules/_markActive.js";

ScrollReveal().reveal('.reveal', {
	distance: '40px',
	easeing: 'ease-in-out',
	interval: 150,
	viewOffset : {
		top: 140
	}
});



