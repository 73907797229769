const markActive = (trigger, data) =>{

	let $target, $data;

	const init = () =>{ 

		$target = $(trigger);
		$data = data

	}
	

	const toggleActive = (e) =>{

		e.preventDefault();
		
		let $groupName = $(e.target).attr($data);

		if( $groupName != 'all' ){
			if( !$(e.target).hasClass('active') ){
				$("[" + $data + "]").removeClass('active');
				$("[" + $data + "='"+ $groupName +"']").addClass('active')
			}
		} else {
			$("[" + $data + "]").removeClass('active');
			$("[" + $data + "]").not('a').addClass('active');
			$("a[" + $data + "='"+ $groupName +"']").addClass('active')
		}
		

		console.log($groupName)
	}

	init();
	$target.on('click', toggleActive);


};


export { markActive };